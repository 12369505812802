import { ActionTree } from 'vuex';
import { GspRoleState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspRole } from '@/shared/backend/gspRole';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspRole, { Value, GspRole, ContactType } from '@/shared/model/gspRole';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.gspRoles');
export const actions: ActionTree<GspRoleState, RootState> = {
  getGspRoles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, contactType?: any }) {
    commit('setGspRolesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getGspRolesSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    let contactType:ContactType | undefined = payload?.contactType === undefined || payload?.contactType === null ? undefined :
          payload?.contactType == 'supplier' ? 'Lieferanten' : 'Mitglieder';

    return defaultBackendGspRole
      .getGspRoles(searchParams,contactType)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspRoles', response.data);
        commit('setGspRolesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspRolesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },


  getGspRoleContactTypes({ commit, dispatch }) {
    return defaultBackendGspRole
      .getGspRoleContactTypes()
      .then((response: AxiosResponse<any>) => {
        commit('setGspRoleContactTypes', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error_getGspRoleContactTypes', commit, e, logger);
        throw e;
      });
  },

  getGspRole({ commit, dispatch }, id: string) {
    return defaultBackendGspRole
      .getGspRole(id)
      .then((response: AxiosResponse<GspRole>) => {
        commit('setGspRole', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspRole({ commit, dispatch }, file: GspRole) {
    return defaultBackendGspRole
      .updateGspRole(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspRole;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspRole_update`), commit);
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspRole({ commit, dispatch }, id: string) {
    return defaultBackendGspRole
      .deleteGspRole(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspRole;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
