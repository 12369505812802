/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { Contact } from '@/shared/model/contact';
import ModuleRoleTable from './module-role-table/module-role-table.vue';
import { GspUserRole } from '@/shared/model/gspUserRole';
import { GspUserRoleDetailInner } from '@/shared/model/gspUserRoleDetail';
import { OdataItems } from '@/shared/model/OdataItems';
import { GspService } from '@/shared/model/gspService';
import { VChip } from 'vuetify/lib';

const logger = new Logger('contact-panel');
const authModule = namespace('auth');
const contactModule = namespace('contact');
const contactPanelModule = namespace('contactPanel');
const gspRoleModule = namespace('gspRole');
const gspUserRoleModule = namespace('gspUserRole');
const gspServiceModule = namespace('gspService');
const gspUserRoleDetailModule = namespace('gspUserRoleDetail');

@Component({
  components: { ModuleRoleTable, VChip },
})
export default class ContactPanelView extends Vue {
  @gspServiceModule.Action('getGspServicesForContacts')
  private actionGetGspServices!: any;
  @gspServiceModule.Getter('getGspServicesForContacts')
  private getterGspServices!: GspService[];

  @authModule.Action('isInGroup')
  private actionIsInGroup!: any;
  @contactModule.Action('updateContactFreigabeZrPortal')
  private updateContactFreigabeZrPortal!: any;

  @gspUserRoleModule.Action('updateGspUserRole')
  private actionUpdateGspUserRole!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private userRoles!: any;

  @gspUserRoleDetailModule.Action('checkContactInManageMaxByZrNummer')
  private checkContactInManageMaxByZrNummer!: any;

  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: any;

  @Prop()
  private contact!: Contact;

  @Prop()
  private userRole!: GspUserRole;

  private async getGspServices() {
    let contactType: string = this.$route.fullPath.includes('/supplier') ? 'supplier' : '';
    // console.log('zebala this.getterGspServices?.items?.length:>> ', this.getterGspServices?.items?.length);

    if (this.getterGspServices?.length > 0) {
      console.log('getGspServices getterGspServices :>> ', this.getterGspServices);
      this.servicesArr = this.getterGspServices;
    } else {
      await this.actionGetGspServices({contactType: contactType})
        .then((result: any) => {
          console.log('getGspServices result :>> ', result);
          this.servicesArr = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }
  private isManagerMax = false;

  servicesArr: any = [];
  // modulesArr: any = [];
  // attributesArr: any = [];

  get services() {
    return this.servicesArr?.items;
  }

  get contactData() {
    return {
      firstName: this.contact.firstName,
      lastName: this.contact.lastName,
      contactId: this.contact.recordID,
      contactAzureId: this.contact.contactAzureId
    }
  }

  moduleRoles: any = [];

  // get hasSelectedData() {
  //   const roleData = this.userRole.

  // }
  hasSelectedData(item: any) {
    const isRoleHasDetails = this.userRole?.gspUserRoleDetails;
    const hasDetailsForThisService = this.userRole?.gspUserRoleDetails?.find((x: any) => x.serviceId === item.id);

    if (!isRoleHasDetails || !hasDetailsForThisService) return false;

    // !!TODO (AD-87) optimize this part by introducing calculated field in `GspUserRoleDetail`
    let result: any = { selected: false };
    if (hasDetailsForThisService.roleDetails) {
      let roleDetailData = hasDetailsForThisService.roleDetails as GspUserRoleDetailInner[];
      // AD-90 if `roleDetailData` is NULL do nothing and show stubs in accordions (contact-panels)
      if (roleDetailData.length === 0) {
        return result;
      }

      let moduleRoles = roleDetailData[0].moduleRoles?.filter((x: any) => x.roles.length > 0);

      // console.log('roleDetailData :>> ', roleDetailData);
      // console.log('roleDetailData[0] :>> ', roleDetailData[0]);
      if (item.zrType == 1) {
        let zrNums = roleDetailData.length ?? '-';
        // console.log('zrNums :>> ', zrNums);
        let selectedZrNums = roleDetailData.filter(
          (x: any) => x.moduleRoles.filter((y: any) => y.roles.length > 0).length > 0
        );
        // console.log('selectedZrNums :>> ', selectedZrNums);
        result.text = this.$t('zr_number_amount', { zrNumbers: selectedZrNums?.length ?? '-', allZrNumbers: zrNums });
      } else {
        let allModules = roleDetailData[0].moduleRoles?.length ?? '-';
        // console.log('roleDetailData[0].moduleRoles.length :>> ', roleDetailData[0].moduleRoles.length);
        // console.log('moduleRoles.length :>> ', moduleRoles.length);
        result.text = this.$t('module_amount', { moduleRoles: moduleRoles?.length ?? '-', allModuleRoles: allModules });
      }

      result.selected = moduleRoles?.length ?? 0 > 0 ? true : false;
    }
    console.log('result hasSelectedData :>> ', result);
    return result;
  }

  async mounted() {
    logger.debug('mounted contact-panel');
    try {
      this.actionIsInGroup(this.contact.email)
      .then((result: any) => {
        logger.log('result :>> ', result);
        const payload = {
          email: this.contact.email,
          isActive: !!result && result > 0,
        };
        this.updateContactFreigabeZrPortal(payload).then((result: any) => {
          logger.log('updateContactFreigabeZrPortal result :>> ', result);
          if (result.result) {
            this.contact.freigabe_zrportal = result.result.freigabe_zrportal;
          }
        });
      })
      .catch((err: any) => {
        logger.error(err);
      });

    if (this.$route.fullPath.includes('/suppliers')) {
       // NO `ManageMax` user for `supplier`
    }  else {
        // (AD-171) check if `Contact` in ManagerMax via API call
        this.checkContactInManageMaxByZrNummer({zrNummer: this.zrNumberArr?.join(',')+''})
        .then((result: any) => {
          logger.log('result :>> checkContactInManageMaxByZrNummer', result);
          if(result.result) {
            this.isManagerMax = true;
          }
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
    
  
    } catch (error) {
      logger.error(error);
    }


    var promiseAll = [this.getGspServices()];
    await Promise.all(promiseAll);
    // const result = this.userRoles.items.find((role:any) => role.contactEmail == this.contact.email);
    // console.log('mounted userRole result :>> ', result);
    // console.log('mounted  contact:>> ', this.contact.email);

    this.updateUserRoleData();
  }

  @Watch('userRole')
  updateUserRoleData() {
    logger.debug('----- updateUserRolesData ---------');

    if (this.userRole?.zrNumRolesData) {
      this.userRoleData = JSON.parse(this.userRole?.zrNumRolesData);

      this.userRoleData.forEach((element: any, index) => {
        if (element?.zrNummer) {
          Vue.set(this.boxes, index, true);
          Vue.set(this.roleBoxes, index, element.roles);
        }
      });
      this.userRolesPayload = this.userRoleData;
    }
  }

  //#region
  isSaving = false;
  userRolesPayload: any = [];

  boxes: Array<boolean> = [false];
  roleBoxes: Array<any> = [];
  userRoleData = [];

  get zrNumberArr() {
    return this.contact.companyZrNumber?.split(',')?.map((x) => x.trim()) || [];
  }

  // get userRole() {
  //   const result = this.userRoles.items.find((role: any) => role.contactEmail == this.contact.email);
  //   return result;
  // }

  get roles() {
    return this.gspRoles.items;
  }
  not(e: any) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e :>> ', e);
  }

  changeZrNumbBox(ev: any, item: any, i: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i :>> ', i);
    console.log('this.gspRoles :>> ', this.gspRoles);
    if (!ev) {
      this.removeZrNumRole('zrNum', i);
    }
  }

  removeZrNumRole(source: any, index: number) {
    this.userRolesPayload[index] = null;
    if (source == 'zrNum') {
      Vue.set(this.roleBoxes, index, null);
    }
  }

  changeRole(ev: any, item: any, i: any) {
    if (ev.length == 0) {
      this.removeZrNumRole('role', i);
    }
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', i);

    let obj = { zrNummer: item.trim(), roles: ev };
    this.userRolesPayload[i] = obj;
    // this.boxes[i] = ev.length>0 ;
    Vue.set(this.boxes, i, ev.length > 0);

    // Array.prototype.splice
    // vm.items.splice(indexOfItem, 1, newValue)
  }

  save() {
    console.log('save roles:>> ');

    const payload = {
      id: this.userRole?.id || undefined,
      contactId: this.contact.recordID,
      contactEmail: this.contact.email,

      zrNumRolesData: JSON.stringify(this.userRolesPayload),
    };
    console.log('payload :>> ', payload);

    this.actionUpdateGspUserRole(payload);
  }
  //#endregion
}
