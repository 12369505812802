import { ActionTree } from 'vuex';
import { ContactState } from './types';
import { RootState } from '../../types';
import { defaultBackendContact } from '@/shared/backend/contact';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import contact, { Value, Contact } from '@/shared/model/contact';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import store from '../../index';
import DownloadUtils from '@/shared/utils/DownloadUtils';
import { OdataItems } from '@/shared/model/OdataItems';
import { CompanyZrResult } from '@/shared/model/company';
import { SearchParams } from '@/shared/model/searchParams';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.contacts');
export const actions: ActionTree<ContactState, RootState> = {
  getContacts({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; searchData?: any }) {
    commit('setContactsIsLoading', true);
    commit('gspUserRole/setGspUserRolesIsLoading', true, {root: true}); // (AD-151) enable `circular` progress bar when change page

    const searchParams = payload?.searchParams ?? getters.getContactsSearchParams;
    // console.log('object.Assign payload?.searchData:>> ', Object.assign(payload?.searchData));
    const searchDataParam: SearchData = payload?.searchData ?? searchData.defaultData();
    searchDataParam.isUserSupportOrSuperAdmin = rootGetters['auth/isUserSupportOrSuperAdmin'];
    // console.log('object.Assign searchDataParam:>> ', Object.assign(searchDataParam));

    // (AD-139) `isInitialRequest` needed to load all contacts (!! for `gsUser`, admin has background request to load all `GspUserRoles` anyway), to get all roles for them on 1st request, this field will be `NULL` (not sent) on other (than initial) requests
    const searchDataParamCopy: any =  {...searchDataParam}; // (AD-173) need this object COPY to correclty handle `filter-dialog` for `GsUser` otherwise the default value for `isActive` will be `Alle`(NULL), not `Aktiv` (true) as should be
    if (searchDataParamCopy.isInitialRequest && !searchDataParamCopy.isUserSupportOrSuperAdmin) {
      searchDataParamCopy.isActive  = null;
      searchParams.dataOption.itemsPerPage = 500;
    }

    return defaultBackendContact
      .getContacts(searchParams, searchDataParamCopy)
      .then((response: AxiosResponse<Value>) => {

        var result = Object.assign({}, response.data);
        console.log('result getContacts :>> ', Object.assign(result));

        if (searchDataParam.isInitialRequest && !searchDataParam.isUserSupportOrSuperAdmin) {
          // (AD-139) on initial request filter `active` Contacts to show them in list + modify count accordingly (!! for `gsUser`, admin has background request to load all `GspUserRoles` anyway)
          var activeContacts = response.data.value.filter((x:Contact)=> x.isActive == true);
          response.data.value = activeContacts;
          response.data['@odata.count'] = activeContacts.length;
        }

        commit('setContacts', response.data);
        // (AD-151) seems that we don't need this code since solved bugs with extra queries
        // if (!searchDataParam.isInitialRequest) {
        //   commit('setContactsIsLoading', false);
        // }
        commit('setContactsIsLoading', false);
        commit('gspUserRole/setGspUserRolesIsLoading', false, {root: true});

        searchDataParam.isInitialRequest = false; // (AD-173) avoid `itemsPerPage = 500` for all requests except 1st for `gsUser`

        return result;

      })
      .catch((e: any) => {
        commit('setContactsIsLoading', false);
        commit('gspUserRole/setGspUserRolesIsLoading', false, {root: true});
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getContact({ commit, dispatch }, id: string) {
    return defaultBackendContact
      .getContact(id)
      .then((response: AxiosResponse<Contact>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getContactByEmail({ commit, dispatch }) {
    const loggedInUserEmail = store.state.auth.azureData.username;
    logger.log('getContactByEmail Action >> loggedInUserEmail :>> ', loggedInUserEmail);
    return defaultBackendContact
      .getContactByEmail(loggedInUserEmail)
      .then((response: AxiosResponse<Contact>) => {
        commit('setLoggedInContact', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCompanyZr({ commit, dispatch, rootGetters }, payload: { isInSuperAdminOrSupportGroup: any; contactType?: any; IsActive: any }) {
    const isAdmin = store.state.auth.isUserInAzureAdminGroup;
    const isInSuperAdminOrSupportGroup = rootGetters['auth/isUserSupportOrSuperAdmin'];
    commit('setCompanyZrIsLoading', true);
    payload.isInSuperAdminOrSupportGroup = isInSuperAdminOrSupportGroup;
    console.log('--ACTION---------------------------------------------------get CompanyZr-------------------------------------  ===>>> contactType  ', payload);
    return defaultBackendContact
      .getCompanyZr(payload)
      .then((response: AxiosResponse<CompanyZrResult[]>) => {
        commit('setCompanyZrIsLoading', false);
        commit('setCompanyZrs', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCompanyZrIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMasterZrGsp({ commit, dispatch, rootGetters }, ids: any) {
    const isAdmin = store.state.auth.isUserInAzureAdminGroup;
    const isInSuperAdminOrSupportGroup = rootGetters['auth/isUserSupportOrSuperAdmin'];
    const payload = {
      contactZrs: ids,
      isAdmin: isInSuperAdminOrSupportGroup,
    };

    return defaultBackendContact
      .getMasterZrGsp(payload)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getContactsExcelReport(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: SearchParams; searchData?: SearchData }
  ) {
    let searchParams = payload?.searchParams ?? getters.getContactsSearchParams;
    const searchDataParam: SearchData = payload?.searchData ?? searchData.defaultData();
    searchDataParam.isUserSupportOrSuperAdmin = rootGetters['auth/isUserSupportOrSuperAdmin'];

    return defaultBackendContact
      .getContactsExcelReport(searchDataParam, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getContactsExcelReport error', commit, e, logger);
        throw e;
      });
  },

  reloadContact({ commit, getters }, contactId: string) {
    // Check if the contact with the passed id is on the currently loaded page.
    let contacts = <OdataItems<Contact>>getters.getContacts;
    if (contacts.items.find((x) => x.recordID == contactId) == undefined) {
      return Promise.resolve();
    }

    return defaultBackendContact
      .getContact(contactId)
      .then((response: AxiosResponse<Contact>) => {
        commit('replaceContact', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateContact({ commit, dispatch }, file: Contact) {
    return defaultBackendContact
      .updateContact(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Contact;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.contact_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateContactRights({ commit, dispatch }, payload: any) {
    return defaultBackendContact
      .updateContactRights(payload)
      .then((response: AxiosResponse<any>) => {
        module_utils.ok(i18n.tc(`success.contact_rights_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateContactFreigabeZrPortal({ commit, dispatch }, payload: any) {
    return defaultBackendContact
      .updateContactFreigabeZrPortal(payload)
      .then((response: AxiosResponse<any>) => {
        // module_utils.ok(i18n.tc(`success.contact_freigabe_zr_portal_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        // module_utils.error('error contact freigabe zr_portal_update', commit, e, logger);
        throw e;
      });
  },
  updateContactAdditionalInfo({ commit }, payload: Contact) {
    return defaultBackendContact
      .updateContactAdditionalInfo(payload)
      .then((response: AxiosResponse<any>) => {
        module_utils.ok(i18n.tc(`success.contact_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deactivateContact({ commit, dispatch }, payload: any) {
    return defaultBackendContact
      .deactivateContact(payload)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteContact({ commit, dispatch }, id: string) {
    return defaultBackendContact
      .deleteContact(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Contact;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateSearchData({ commit, dispatch }, data: any) {
    commit('setSearchData', data);
  },

  // (bad approach, since will trigger the 2nd request to backend)
  updateSearchDataIsActive({ commit, dispatch }, data: any) {
    commit('setSearchDataIsActive', data);
  },

  updateContactsIsLoading({ commit, dispatch }, data: any) {
    commit('setContactsIsLoading', data);
  },
  updateCompanyZrIsLoading({ commit, dispatch }, data: any) {
    commit('setCompanyZrIsLoading', data);
  },

  countHouseAdminsInZrCluster({ commit }, contact: Contact) {
    return defaultBackendContact
      .countHouseAdminsInZrCluster(contact)
      .then((response) => response.data)
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
